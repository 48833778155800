import React from 'react';
import { useState, useEffect } from 'react';
import '../App.css';
import loadingGif from '../images/loading.gif'
import { useQuery } from '@apollo/react-hooks';
import gql from "graphql-tag";
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Label
} from 'recharts';


function DonationTrends() {
  let [search, setSearch] = useState("aps_education_f");

  let GetDonationInfo = gql`
  {
    allDonations(search: "${search}") {
      edges {
          node {
            id
            amount
            description
            created
            likeCount
            target {
                id
                name
                category {
                  id
                }
            }
          }
        }
      }
      allNonprofits {
        edges {
          node {
            name
          }
        }
      }
  }`

  const { data, loading, error } = useQuery(GetDonationInfo);

  if (loading){
    return(
      <div>
        <h3 className="chart-title">Donation Trends</h3>
        <img src={loadingGif} id="loading-gif"/>
      </div>
    )
  }
  if (error){
    console.log(error);
    return <p>Error</p>;
  }

  let donationsList = data.allDonations.edges;

  let graphData = [];

  const monthObj = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec"
  }

  for(let i = 0; i < donationsList.length; i++){
    let created = donationsList[i].node.created;
    let donationDate = new Date(created);
    let amount = donationsList[i].node.amount;
    amount = amount/100;

    let donationObj = {
      name: monthObj[donationDate.getMonth()],
      ibis: amount
    }

    graphData.push(donationObj);
  }

  let nonprofitsList = data.allNonprofits.edges;

  let dropdownEntries = []

  for(let j = 0; j < nonprofitsList.length; j++){
    let name = nonprofitsList[j].node.name;
    dropdownEntries.push(name)
  }

  var options = dropdownEntries
      .map(function(nonprofitName) {
        return (
          <option value={nonprofitName}>{nonprofitName}</option>
        );
      });



  return (
    <div>
      <h3 className="chart-title">Donation Trends</h3>

      <div className="form-group">
        <select className="form-control" id="sel1" value={search} onChange={e=> setSearch(e.target.value)}>
          {options}
        </select>
      </div>

      <AreaChart
        width={800}
        height={400}
        data={graphData}
        margin={{
          top: 10, right: 30, left: 0, bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name">
          <Label value="Time" offset={5} position="bottom" />
        </XAxis>
        <YAxis label={{ value: "Ibis Donated", angle: -90, position: "insideLeft" }} />
        <Tooltip />
        <Area type="monotone" dataKey="ibis" stroke="#84AB3F" fill="#414042" />
      </AreaChart>
    </div>
  );
}

export default DonationTrends;
