import React from 'react';
import '../App.css';
import loadingGif from '../images/loading.gif'
import { useQuery } from '@apollo/react-hooks';
import gql from "graphql-tag";
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, LabelList
} from 'recharts';

const GetDonationInfo = gql`
{
  allDonations {
    edges {
        node {
          id
          amount
          description
          created
          likeCount
          target {
              id
              name
              category {
                id
              }
          }
        }
      }
    }
}`


function TopTenNonprofits() {
  const { data, loading, error } = useQuery(GetDonationInfo);

  if (loading){
    return(
      <div>
        <h3 className="chart-title">Top 10 Nonprofits by Donations</h3>
        <img src={loadingGif} id="loading-gif"/>
      </div>
    )
  }
  if (error){
    console.log(error);
    return <p>Error</p>;
  }

  let nonprofitObj = {};

  let donationsList = data.allDonations.edges;

  let filteredDonationsList = []

  for(let i = 0; i < donationsList.length; i++){
    let name = donationsList[i].node.target.name;
    let amount = donationsList[i].node.amount;
    amount = amount/100;

    if(nonprofitObj[name] === undefined){
      nonprofitObj[name] = amount;
    } else{
      nonprofitObj[name] += amount;
    }
  }


  let graphDataUnsorted = [];

  const entries = Object.entries(nonprofitObj);

  const amountsList = Object.values(nonprofitObj);

  var topAmounts = [...amountsList].sort((a,b) => b-a).slice(0,10);


  for(let j = 0; j < entries.length; j++){
    let entriesName = entries[j][0];
    let entriesAmount = entries[j][1];
    for(let m = 0; m < topAmounts.length; m++){
      if(entriesAmount == topAmounts[m]){
        let obj = {
          name: entriesName,
          ibis: Math.round(entriesAmount)
        }
        graphDataUnsorted.push(obj);
        break;
      }
    }
  }

  const graphData = [...graphDataUnsorted].sort((a,b) => (b.ibis - a.ibis))


  const COLORS = ['#414042', '#84AB3F','#2F4F4F', '#006400','#C0C0C0', '#20B2AA','#000000', '#32CD32', '#000', '#111'];

  return (
    <div>
      <h3 className="chart-title">Top 10 Nonprofits by Donations</h3>

      <BarChart
        width={800}
        height={370}
        data={graphData}
        margin={{
          top: 5, right: 30, left: 20, bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis dataKey="name" hide={true}>
          <Label value="Nonprofit" offset={5} position="bottom" />
        </XAxis>
        <YAxis height={600} label={{ value: 'Ibis', angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Bar dataKey="ibis" fill="#84AB3F" barSize={30}>
          <LabelList dataKey="ibis" position="top" />
        </Bar>
      </BarChart>
    </div>
  );
}

export default TopTenNonprofits;
